import { environment as environment } from '../../environments/environment';
'use strict';
// export const base_api_url: string = "http://139.99.21.181:8081";
// export const base_api_url: string = 'http://api.municipal.logikoof.in';
export const base_api_url: string = environment.baseUrl;
// export const base_api_url_file: string = environment.baseUrlfile;

//export const base_api_url: string = "https://api.urncc.org";


export const base=environment.base

